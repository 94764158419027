// TODO: update the recourse to be parsed from amp response

enum Recourse {
  IDV = 'IDV',
  Phone = 'PHONE',
  Email = 'EMAIL',
  AddedEmail = 'AddedEmail',
  ParentConsentRequest = 'ParentConsentRequest',
  ParentLinkRequest = 'ParentLinkRequest'
}

export default Recourse;
