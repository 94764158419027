import React, { useEffect } from 'react';
import { TranslateFunction } from 'react-utilities';
import useModal from '../../../hooks/useModal';
import { Recourse, UpsellStage } from '../../../enums';
import { setRecourse, setStage } from '../../accessManagementSlice';
import { useAppDispatch } from '../../../store';
import { PrologueConstants } from '../../constants/viewConstants';

const IdvAndVpcPrologue = ({
  translate,
  onHide,
  translatedTitle,
  translatedPrompt
}: {
  translate: TranslateFunction;
  onHide: () => void;
  translatedTitle?: string;
  translatedPrompt?: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const defaultPromp = 'First verify your ID or get parent permission.';
  const bodyText = translatedPrompt ? `${translatedPrompt}, ${defaultPromp}` : defaultPromp;

  const changeAgeBody = (
    <div>
      <div className='text-description'> {bodyText} </div>
    </div>
  );

  const [IdvAndVpcSelectionModal, IdvAndVpcSelectionModalService] = useModal({
    title: translatedTitle,
    body: changeAgeBody,
    dualActionButton: true,
    actionButton: 'Verify ID',
    neutralButton: 'Email My Parent',
    onAction: () => {
      dispatch(setRecourse(Recourse.IDV));
      dispatch(setStage(UpsellStage.Verification));
      IdvAndVpcSelectionModalService.close();
    },
    size: 'sm',
    onHide,
    onNeutral: () => {
      dispatch(setRecourse(Recourse.ParentConsentRequest));
      dispatch(setStage(UpsellStage.Verification));
      IdvAndVpcSelectionModalService.close();
    }
  });

  // Trigger the opening of the error modal in response to a user action or effect
  useEffect(() => {
    IdvAndVpcSelectionModalService.open();
  }, []);

  return IdvAndVpcSelectionModal;
};

export default IdvAndVpcPrologue;
