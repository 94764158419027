import React, { useState } from 'react';
import { TranslateFunction } from 'react-utilities';
import { Modal } from 'react-style-guide';
import { useSelector } from 'react-redux';
import {
  resetAccessManagementStore,
  selectCurrentStage,
  selectFeatureAccess,
  selectCurrentRecourse,
  selectShowUpsell,
  setRecourse,
  setRedirectLink,
  setStage,
  fetchFeatureAccess
} from './accessManagementSlice';
import { useAppDispatch } from '../store';
import { ModalEvent, AccessManagementUpsellEventParams } from './constants/viewConstants';
import EmailVerificationContainer from '../recourses/emailVerification/EmailVerificationContainer';
import IDVerificationContainer from '../recourses/IDVerification/IDVerificationContainer';
import { resetVerificationStore } from '../recourses/IDVerification/verificationSlice';
import { Access, UpsellStage, Recourse } from '../enums';
import Prologue from './components/Prologue';
import Epilogue from './components/Epilogue';
import VpcPrologue from './components/DefaultPrologue/VpcPrologue';
import IdvPrologue from './components/DefaultPrologue/IdvPrologue';
import IdvAndVpcPrologue from './components/DefaultPrologue/IdvAndVpcPrologue';
import ParentalRequestContainer from '../recourses/parentalRequest/ParentalRequestContainer';
import { TRecourse } from '../types/Recourse';
import ParentalConsentType from '../recourses/parentalRequest/enums/ParentalConsentType';

function AccessManagementContainer({
  translate
}: {
  translate: TranslateFunction;
}): React.ReactElement {
  let displayContainer;
  const dispatch = useAppDispatch();
  const currentStage = useSelector(selectCurrentStage);
  const featureAccess = useSelector(selectFeatureAccess);
  const showUpsellModal = useSelector(selectShowUpsell);
  const currentRecourse = useSelector(selectCurrentRecourse);
  const [onHidecallback, setOnHideCallback] = useState<(access: Access) => string>(
    (access: Access) => access
  );

  const [asyncExit, setAsyncExit] = useState<boolean>(true);
  const [optionalArgsValue, setOptionalArgsValue] = useState<Record<string, string>>({});
  // For VPC integration testing purpose
  // const [optionalArgsValue, setOptionalArgsValue] = useState<Record<string, string>>({
  //   newBirthdate: '1995-3-01'
  // });

  function onAccessManagementCustomEvent(event: CustomEvent<AccessManagementUpsellEventParams>) {
    const {
      featureName,
      redirectLink,
      optionalArgs,
      isAsyncCall,
      enablePrologue,
      closeCallback
    } = event.detail;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(fetchFeatureAccess(featureName));
    dispatch(setRedirectLink(redirectLink));
    setOnHideCallback(() => (access: Access): string => closeCallback(access));
    setAsyncExit(isAsyncCall);
    setOptionalArgsValue(optionalArgs);
    if (enablePrologue) {
      dispatch(setStage(UpsellStage.Prologue));
    }
  }

  function onHide() {
    dispatch(resetAccessManagementStore());
    onHidecallback(featureAccess.data.access);
  }

  function asyncOnHide() {
    dispatch(resetAccessManagementStore());
    onHidecallback(Access.Denied);
  }

  const onHideFunction = asyncExit ? asyncOnHide : onHide;

  window.addEventListener(
    ModalEvent.StartAccessManagementUpsell,
    onAccessManagementCustomEvent as EventListener
  );

  // For VPC integration testing purpose
  /* const VpcRecourse: TRecourse = {
    action: Recourse.ParentLinkRequest,
    recourseData: {
      consentType: ParentalConsentType.UpdateBirthdate
    }
  }; */
  // Hard code the value here for now
  const translatedTitle = 'Verify Your Age';
  const translatedPrompt = 'To change your birthday';

  function getVerificationContainer() {
    if (!currentRecourse) {
      dispatch(setRecourse(featureAccess.data.recourse[0]));
    }
    switch (currentRecourse) {
      case Recourse.AddedEmail:
        return (
          <EmailVerificationContainer
            translate={translate}
            recourse={currentRecourse}
            onHide={onHide}
          />
        );

      case Recourse.IDV:
        return <IDVerificationContainer translate={translate} onHidecallback={onHideFunction} />;
      /*      case Recourse.ParentConsentRequest:
      case Recourse.ParentLinkRequest:
        return (
          <ParentalRequestContainer
            recourse={VpcRecourse}
            translate={translate}
            onHidecallback={onHideFunction}
            value={optionalArgsValue}
          />
        ); */
      default:
        return <IDVerificationContainer translate={translate} onHidecallback={onHideFunction} />;
    }
  }

  function getPrologueContainer() {
    const recourses = featureAccess.data.recourse;
    if (recourses.length === 1) {
      switch (recourses[0]) {
        case Recourse.ParentConsentRequest:
        case Recourse.ParentLinkRequest:
          return (
            <VpcPrologue
              translate={translate}
              onHide={onHideFunction}
              translatedTitle={translatedTitle}
              translatedPrompt={translatedPrompt}
            />
          );
        case Recourse.IDV:
          return (
            <IdvPrologue
              translate={translate}
              onHide={onHideFunction}
              translatedTitle={translatedTitle}
              translatedPrompt={translatedPrompt}
            />
          );
        default:
          break;
      }
    }

    if (recourses.length === 2) {
      if (recourses.includes(Recourse.IDV)) {
        if (
          recourses.includes(Recourse.ParentConsentRequest) ||
          recourses.includes(Recourse.ParentLinkRequest)
        ) {
          return (
            <IdvAndVpcPrologue
              translate={translate}
              onHide={onHideFunction}
              translatedTitle={translatedTitle}
              translatedPrompt={translatedPrompt}
            />
          );
        }
      }
    }

    // If there is no existing default component,, pick first recourse and go to
    // verification Upsell directly
    dispatch(setRecourse(featureAccess.data.recourse[0]));
    dispatch(setStage(UpsellStage.Verification));
    return null;
  }

  switch (currentStage) {
    case UpsellStage.Prologue:
      if (featureAccess.data != null) {
        displayContainer = getPrologueContainer();
      }
      break;
    case UpsellStage.Verification:
      if (featureAccess.data != null) {
        displayContainer = getVerificationContainer();
      }
      break;
    case UpsellStage.Epilogue:
      displayContainer = <Epilogue translate={translate} />;
      break;
    default:
      displayContainer = <Prologue translate={translate} />;
      break;
  }

  return (
    <React.Fragment>
      <Modal
        show={showUpsellModal}
        onHide={onHide}
        size='sm'
        aria-labelledby='access-management-modal-title'
        className='access-management-upsell-modal'
        scrollable='true'
        centered='true'>
        {displayContainer}
      </Modal>
    </React.Fragment>
  );
}
export default AccessManagementContainer;
