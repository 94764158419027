import { Access } from '../../enums';

export const ModalEvent = {
  StartAccessManagementUpsell: 'StartAccessManagementUpsell'
};

export type AccessManagementUpsellEventParams = {
  featureName: string;
  redirectLink: string;
  optionalArgs: Record<string, string>;
  isAsyncCall: boolean;
  enablePrologue: boolean;
  closeCallback: (access: Access) => string;
};

export const PrologueConstants = {
  Action: {
    Cancel: 'Action.Cancel',
    EmailMyParent: 'Action.EmailMyParent'
  },
  Error: {
    TryAgain: 'Response.ErrorTryAgain'
  }
};
