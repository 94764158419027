import React, { useEffect } from 'react';
import { TranslateFunction } from 'react-utilities';
import useModal from '../../../hooks/useModal';
import { Recourse, UpsellStage } from '../../../enums';
import { setRecourse, setStage } from '../../accessManagementSlice';
import { useAppDispatch } from '../../../store';
import { PrologueConstants } from '../../constants/viewConstants';

const VpcPrologue = ({
  translate,
  onHide,
  translatedTitle,
  translatedPrompt
}: {
  translate: TranslateFunction;
  onHide: () => void;
  translatedTitle?: string;
  translatedPrompt?: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const defaultVpcPromp = "You will need a parent's help.";
  const bodyText = translatedPrompt ? `${translatedPrompt}, ${defaultVpcPromp}` : defaultVpcPromp;

  const changeAgeBody = (
    <div>
      <div className='text-description'> {bodyText} </div>
    </div>
  );

  const [requireVpcModal, requireVpcModalService] = useModal({
    title: translatedTitle,
    body: changeAgeBody,
    actionButton: 'Email My Parent',
    neutralButton: translate(PrologueConstants.Action.Cancel),
    onAction: () => {
      dispatch(setRecourse(Recourse.ParentConsentRequest));
      dispatch(setStage(UpsellStage.Verification));
      requireVpcModalService.close();
    },
    size: 'sm',
    onHide,
    onNeutral: onHide
  });

  // Trigger the opening of the error modal in response to a user action or effect
  useEffect(() => {
    requireVpcModalService.open();
  }, []);

  return requireVpcModal;
};

export default VpcPrologue;
