import React, { useEffect } from 'react';
import { TranslateFunction } from 'react-utilities';
import useModal from '../../../hooks/useModal';
import { Recourse, UpsellStage } from '../../../enums';
import { setRecourse, setStage } from '../../accessManagementSlice';
import { useAppDispatch } from '../../../store';
import { PrologueConstants } from '../../constants/viewConstants';

const IdvPrologue = ({
  translate,
  onHide,
  translatedTitle,
  translatedPrompt
}: {
  translate: TranslateFunction;
  onHide: () => void;
  translatedTitle?: string;
  translatedPrompt?: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const defaultIdvPromp = 'Please verify with an ID.';
  const bodyText = translatedPrompt ? `${translatedPrompt}, ${defaultIdvPromp}` : defaultIdvPromp;

  const changeAgeBody = (
    <div>
      <div className='text-description'> {bodyText} </div>
    </div>
  );

  const [requireIdvModal, requireIdvModalService] = useModal({
    title: translatedTitle,
    body: changeAgeBody,
    actionButton: 'Verify ID',
    neutralButton: translate(PrologueConstants.Action.Cancel),
    onAction: () => {
      dispatch(setRecourse(Recourse.IDV));
      dispatch(setStage(UpsellStage.Verification));
      requireIdvModalService.close();
    },
    size: 'sm',
    onHide,
    onNeutral: onHide
  });

  // Trigger the opening of the error modal in response to a user action or effect
  useEffect(() => {
    requireIdvModalService.open();
  }, []);

  return requireIdvModal;
};

export default IdvPrologue;
